<template>
  <div>
    <steps ref="steps"></steps>
    <div class="border">
      <div class="flex Application">
        <div style="margin-right: 5px">申请状态:{{ statusTitle }}</div>
        <i class="el-icon-refresh icons" @click="RefresshClick"></i>
      </div>
      <div>
        <div style="text-align: center">
          <div style="font-size: 19px; font-weight: bold">
            使用下方手机号码,登录中金签约平台进行签约操作
          </div>
          <div style="margin-top: 20px;display: flex;align-items: center;justify-content: center;">
            <div>法人本人手机号：</div>
            <div>
              <el-input v-model="input" placeholder="请输入法人本人手机号"></el-input>
            </div>

          </div>
        </div>
      </div>
      <div class="flex inputOn" style="margin-top: 50px">
        <el-button type="primary" @click="addClick">去签约</el-button>
      </div>
    </div>
  </div>
</template>

<script>
  import { getAccountStatusForZJ, signContractForZJ } from "@/api/Accountopening";
  import * as types from "@/store/action-types";
  import Vue from "vue";
  export default {
    data() {
      return {
        input: "",
        statusTitle: "未申请",
      };
    },
    mounted() {
      this.input = window.localStorage.getItem("phone");
      this.getStatusForZJ();
    },
    methods: {
      RefresshClick() {
        this.getStatusForZJ();
        this.$message({
          message: '刷新成功',
          type: "success",
        });
      },
      getStatusForZJ() {
        getAccountStatusForZJ().then((res) => {
          if (res.code == 0) {
            this.bindCardTxsn = res.data.bindCardTxsn;
            if (res.data.signStatus == 10) {
              this.statusTitle = "受理成功(待签约)";
              // this.$router.push({
              //   path:'/manage'
              // })
            }
            if (res.data.signStatus == 20) {
              this.statusTitle = "受理失败";
            }
            if (res.data.signStatus == 30) {
              this.statusTitle = "已签约";
              Vue.ls.set(types.active, 4);
              this.$emit("logoutHeader4", 4);
              this.$refs.steps.getactive();
              setTimeout(() => {
                this.$router.push({
                  path: '/manage'
                })
              }, 3000);

            }
            // if (res.data.signStatus == 40) {
            //   this.statusTitle = "确认绑卡失败";
            // }
          }
        });
      },
      addClick() {
        let config = {
          signPhoneNumber: this.input,
        };
        signContractForZJ(config).then((res) => {
          if (res.code == 0) {
            this.$message({
              message: res.message,
              type: "success",
            });

            this.getStatusForZJ();
            if (res.data.signStatus == 10) {
              window.open(res.data.signUrl);
            }
          } else {
            this.$message.error(res.message);
          }
        });
        // Vue.ls.set(types.active, 4);
        // this.$emit("logoutHeader4", 4);
        // this.$refs.steps.getactive();
      },
    },
    components: {
      steps: () => import("./steps.vue"),
    },
  };
</script>

<style lang="scss" scoped>
  .border {
    border: 1px solid black;
    width: 100%;
    padding: 20px;
  }

  .Application {
    justify-content: right;
    margin-bottom: 5px;
  }

  .flex {
    display: flex;
    align-items: center;
  }

  .icons {
    cursor: pointer;
  }

  .inputOn {
    justify-content: center;
  }

  ::v-deep .el-input__inner {
    /* width: 300px; */
    /* margin-left: 10px; */
  }
</style>